import * as styles from "./blog-post.module.css";
import { differenceInDays, formatDistance, format } from "date-fns";
import PeopleList from "./people-list";
import ContainerForPages from "./containerForPages";
import Container from "./container";
import PortableText from "./portableText";
import React, { useEffect, useState, useRef } from "react";
import { buildImageObj, publishDate } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import SideBar from "./sideBar";
import AnchorMenu from "./sidebarComponents/anchorMenu";
import FooterDisclaimer from "./footer-disclaimer";
import ArticleGatedContentDecider from "./gatedContent/articleGatedContentDecider";
import LatestArticlesGroup from "./landingPageComponents/blocks/latestArticlesBlock";

import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { MainImage } from "gatsby-plugin-image";
import CtaWidget from "./ctaWidget/ctaWidget";
import WidgetComponent from "./sidebarComponents/widgetComponent";
import ListComments from "./comments/listComments";
import PostCommentForm from "./comments/postCommentForm";
import SocialIconsWidget from "./widgets/socialIconsWidget/socialIconsWidget";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    borderRadius: "12px",
  },
  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500",
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  authorMargin: {
    marginTop: "31px",
  },
  dateMargin: {
    color: "#2F2F4E",
    paddingBottom: "15px",
  },
  grey: {
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000px' height='1000px'%3E%3Cdefs%3E%3ClinearGradient id='g1'%3E%3Cstop stop-color='%239796F0'/%3E%3Cstop offset='1' stop-color='%23FBC7D4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle cx='500' cy='500' r='400' style='fill-opacity: .1;' fill='url(%23g1)'/%3E%3C/svg%3E")`,
    // backgroundRepeat: "repeat-y",
    // backgroundSize: "1000px 1000px",
    // backgroundPosition: "top center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      backgroundSize: "contain",
    },
  },
  modalBackground: {
    // backdropFilter: "blur(100px)",
    maxWidth: "100%",
    paddingBottom: "60px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]:{
      paddingTop: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  seventyFive: {
    width: "75%",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  twentyFive: {
    width: "25%",
    justifyContent: "flex-end",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  marginFromTitle: {
    marginTop: "24px",
    marginBottom: "0px",
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px",
  },
  sidebar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  adContent: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  postCommentTitle: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  postCommentStroke: {
    height: "1px",
    width: "100%",
    background: "#DBE3EF",
  },
  postCommentParagraph: {
    paddingBottom: "1.5em",
  },
  disclaimerFlexContainer: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bodyContainer: {
    maxWidth: "1170px",
  },
  spacerLeft: {
    alignSelf: "flex-start",
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("md")]: {
      top: "72px",
      flex: 1,
      marginLeft: "auto",
      paddingRight: "24px",
    },
  },
  spacerRight: {
    alignSelf: "flex-start",
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("md")]: {
      top: "72px",
      marginRight: "auto",
      paddingLeft: "24px",
      flex: 1,
    },
  },
  breadcrumbs:{
    paddingTop: "14px",
    paddingBottom: "24px",
  },
  relatedContentTitle:{
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "47px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",
    color: "#3C465F",
    marginTop: "72px",
    marginBottom: "25px",
    [theme.breakpoints.down("md")]:{
      marginTop: "48px",
      marginBottom: "16px",
    }
  },
  subtitle:{
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#2F2F4E",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: 0,
    [theme.breakpoints.down("md")]:{
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
  },
  subtitleNoAuthor:{
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#2F2F4E",
    paddingTop: "24px",
    marginBottom: 0,
    [theme.breakpoints.down("md")]:{
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
  },
}));

function BlogPost(props) {
  const {
    _rawBody,
    authors,
    authorsText,
    categories,
    title,
    subtitle,
    template,
    mainImage,
    publishedAt,
    widgets,
    linkMenu,
    _id,
    enableComments,
    enableGatedContent,
    gateStyle,
    brazeSubscriptionGroupId,
    emailSignupWidgetTitle,
    emailSignupWidgetImage,
    emailSignupWidgetParagraphText,
    emailSignupWidgetButtonText,
    textNextToKey,
    emailSignupWidgetTextboxPlaceholder,
    _rawGatedContentBody,
    _rawGatedContentReadMoreText,
    brazeCustomAttributes,
    brazeCustomAttributesSB,
    brazeSubscriptionRef,
    hideSocialIconsTop,
    allArticles,
    hideRelatedContent,
  } = props;

  var brazeSubscriptionRefGate = ""

  if (brazeSubscriptionRef && brazeSubscriptionRef.groupID) {
    brazeSubscriptionRefGate = brazeSubscriptionRef.groupID
  }

  //console.log("page-brazeSubscriptionRef", brazeSubscriptionRefGate)


  let authorsTextWithMarkDefs = authorsText;
  authorsText && authorsTextWithMarkDefs.map((obj) => (obj.markDefs = []));
  var catsList = "";
  for (var i = 0; i < categories.length; i++) {
    catsList += categories[i].title;
  }
  var styleContent = styles.mainContent;
  var styleBar = styles.metaContent;
  const [breadcrumbLabel, setBreadcrumbLabel] = useState("");
  const [breadcrumbLink, setBreadcrumbLink] = useState("");
  const [pageTopRefOffset, setPageTopRefOffset] = useState();
  const [shareIconsReady, setShareIconsReady] = useState(false);
  const [shouldHideRelatedContent, setShouldHideRelatedContent] = useState(false);

  const pageTopRef = useRef(null);
  const theme = useTheme();
  const matchesTabletUpwards = useMediaQuery(theme.breakpoints.up("md"));
  const anchorMobileBreakpoint = useMediaQuery("(max-width: 1279px)");

  useEffect(() => {
    setTimeout(() => {
      setPageTopRefOffset(pageTopRef.current.offsetTop);
      setShareIconsReady(true);
    }, 100);
  }, [pageTopRef]);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("/learn/articles/")) {
      setBreadcrumbLabel("Articles");
      setBreadcrumbLink("/learn/articles/");
    } else if (url.includes("/learn/investing-guides/")) {
      setBreadcrumbLabel("Money guides");
      setBreadcrumbLink("/learn/investing-guides/");
    } else if (url.includes("/learn/learning-paths/")) {
      setBreadcrumbLabel("Money goals");
      setBreadcrumbLink("/learn/learning-paths/");
    }
  }, []);

  let direction;

  if (template == "C-SB") {
    styleContent = styles.contentLeft;
    styleBar = styles.sidebarRight;
    direction = "row";
  }

  if (template == "SB-C") {
    styleContent = styles.contentRight;
    styleBar = styles.sidebarLeft;
    direction = "row-reverse";
  }
  const classes = useStyles();

  var exists = false;
  if (typeof window !== "undefined") {
    exists = window.getACookie("BrazeGroups");

    var showGate = false;

    if (exists == "false") {
      showGate = false;
    }
    else if (exists.includes(brazeSubscriptionRefGate)) {
      showGate = true;
    }
    else {
      showGate = false;
    }
  }

  return (
    <article className={classes.grey}>
      <ContainerForPages>
        <Grid
          container
          item
          alignItems="center"
          justify="space-between"
          className={
            theme === "Boring Money Campus theme"
              ? classes.containerForContent
              : null
          }
        >
          <Grid className={classes.spacerLeft} item xs={0} md={1}>
            {(!hideSocialIconsTop) && (!anchorMobileBreakpoint && !template) || (!hideSocialIconsTop) && (!anchorMobileBreakpoint &&
              template === "C-SB") ? (
              shareIconsReady &&
              <SocialIconsWidget
                isSidebar={true}
                offsetTop={pageTopRefOffset}
                template={template}
              />
            ) : <></>
            }
          </Grid>

          <Grid item xs={11} md={10} className={classes.bodyContainer}>
            <Hidden smDown implementation="css">
              <Typography className={classes.breadcrumbs} variant="subtitle2">
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linkTextNotActive}
                >
                  Home
                </Link>
                {breadcrumbLabel && breadcrumbLink && (
                  <span className={classes.dot}>{" • "}</span>
                )}
                {breadcrumbLabel && breadcrumbLink && (
                  <Link
                    to={breadcrumbLink}
                    style={{ textDecoration: "none" }}
                    className={classes.linkTextActive}
                  >
                    {breadcrumbLabel}
                  </Link>
                )}
              </Typography>
            </Hidden>
            <Grid
              container
              className={classes.modalBackground}
              direction={direction}
            >
              <Grid item className={classes.content}>
                <div ref={pageTopRef}></div>
                <Typography ref={pageTopRef} variant="h1" className={styles.bigtitle}>
                  {title}
                </Typography>
                {/* <p className={styles.bigtitle}>{subtitle}</p> */}
                {subtitle &&
                  <Typography variant={"h2"} className={classes.subtitle}>
                    {subtitle}
                  </Typography>
                }
                <Grid
                  container
                  item
                  alignItems="stretch"
                  justify="space-between"
                  className={!subtitle && classes.marginFromTitle}
                >
                  <Grid
                    container
                    item
                    className={classes.seventyFive}
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    {authorsTextWithMarkDefs && (
                      <PortableText blocks={authorsTextWithMarkDefs} />
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    className={classes.twentyFive}
                    alignItems="flex-start"
                  >
                    {publishedAt && (
                      <Typography
                        variant="body1"
                        className={classes.dateMargin}
                      >
                        {publishDate(publishedAt)}
                      </Typography>
                    )}
                  </Grid>

                  {!hideSocialIconsTop && anchorMobileBreakpoint &&
                    <SocialIconsWidget  />
                  }
                  {/* <CtaWidget/> */}
                </Grid>
                {/* no main image, only as a display in root */}
                {/* {mainImage && mainImage.asset && (
              <div className={styles.mainImage}>
                <img
                  className={classes.mainImage}
                  src={imageUrlFor(buildImageObj(mainImage))
                    .width(1200)
                    .height(Math.floor((9 / 16) * 1200))
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={mainImage.alt}
                />
              </div>
            )} */}

                {/* {authors && <PeopleList items={authors} title="Authors" />} */}


                <ArticleGatedContentDecider
                  enableGatedContent={enableGatedContent}

                  brazeSubscriptionRefGate={brazeSubscriptionRefGate}
                  emailSignupWidgetTitle={emailSignupWidgetTitle}
                  emailSignupWidgetImage={emailSignupWidgetImage}
                  emailSignupWidgetParagraphText={
                    emailSignupWidgetParagraphText
                  }
                  emailSignupWidgetButtonText={emailSignupWidgetButtonText}
                  emailSignupWidgetTextboxPlaceholder={
                    emailSignupWidgetTextboxPlaceholder
                  }
                  _rawGatedContentBody={_rawGatedContentBody}
                  _rawGatedContentReadMoreText={_rawGatedContentReadMoreText}
                  gateStyle={gateStyle}
                  _rawBody={_rawBody}
                  brazeCustomAttributes={brazeCustomAttributes}
                  textNextToKey={textNextToKey}
                  showGate={showGate}
                />



                {/* {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Category</h3>

                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}*/}

                {enableComments && (
                  <Grid container>
                    <div className={classes.postCommentStroke}></div>

                    <Typography
                      variant="h3"
                      className={classes.postCommentTitle}
                    >
                      Post a comment:
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.postCommentParagraph}
                    >
                      This is an open discussion and does not represent the
                      views of Boring Money. We want our communities to be
                      welcoming and helpful. Spam, personal attacks and
                      offensive language will not be tolerated. Posts may be
                      deleted and repeat offenders blocked at our discretion.
                    </Typography>
                  </Grid>
                )}

                <Grid container>
                  {enableComments && <PostCommentForm pageId={_id} />}
                </Grid>

                <Hidden smDown implementation="js">
                  <Grid container>
                    {enableComments && (
                      <ListComments itemsPerPage={6} pageId={_id} />
                    )}
                  </Grid>
                </Hidden>

                <Hidden mdUp implementation="js">
                  <Grid container>
                    {enableComments && (
                      <ListComments itemsPerPage={4} pageId={_id} />
                    )}
                  </Grid>
                </Hidden>
              </Grid>
              <Grid item sm={0} className={classes.sidebar}>
                {linkMenu.length > 0 &&
                _rawBody.some((item) => item._type === "anchorMenuMobile") &&
                !matchesTabletUpwards ? (
                  <></>
                ) : linkMenu.length > 0 ? (
                  <AnchorMenu menuItems={linkMenu[0]} />
                ) : (
                  <></>
                )}

                <WidgetComponent
                  widgets={widgets}
                  brazeCustomAttributesSB={brazeCustomAttributesSB}
                />

                {/*{widgets && (
              <SideBar
                title="Sidebar"
                categoryList={catsList}
                widgets={widgets}
              />
            )} */}
              </Grid>
            </Grid>

            <Hidden smDown implementation="js">
              <Grid item className={classes.adContent}>
                <WidgetComponent widgets={widgets} target="bottom" />
              </Grid>
            </Hidden>
            {categories && !shouldHideRelatedContent && !hideRelatedContent &&
              <div>
                <Typography className={classes.relatedContentTitle} variant={"h2"}>
                  You may also like...
                </Typography>
                <LatestArticlesGroup articleCount={3} categories={categories}
                                     allArticles={allArticles} noSpacer={true}
                                     setShouldHideRelatedContent={setShouldHideRelatedContent}
                                     title={title}
                />
              </div>
            }
          </Grid>

          <Grid className={classes.spacerRight} item xs={0} md={1}>
            {!hideSocialIconsTop && !anchorMobileBreakpoint &&
              template === "SB-C" && (
                shareIconsReady &&
                <SocialIconsWidget
                  isSidebar={true}
                  offsetTop={pageTopRefOffset}
                  template={template}
                />
              )
            }
          </Grid>
        </Grid>
        <Grid
          className={classes.disclaimerFlexContainer}
          md={10}
          xs={11}
          item
          justify="center"
        >
          <FooterDisclaimer />
        </Grid>
      </ContainerForPages>
    </article>
  );
}

export default BlogPost;
